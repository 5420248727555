
import React from "react";
import { Contaienr } from "./styles";

const PolicyPrivacyPage = () => {
  return (
    <Contaienr>
      <h1>Política de Privacidade do Task App</h1>
      <p>A sua privacidade é importante para nós. É política do Task App respeitar a sua privacidade em relação a qualquer informação que possamos coletar enquanto opera nosso aplicativo. Esta Política de Privacidade descreve como tratamos suas informações pessoais ao usar nosso aplicativo.</p>
      <p><strong>Informações que Coletamos:</strong> Podemos coletar e armazenar informações pessoais que você nos fornece, como nome, endereço de e-mail e informações de contato.</p>
      <p><strong>Uso das Informações:</strong> As informações pessoais que coletamos são usadas para fornecer e melhorar nosso aplicativo. Podemos usar suas informações para entrar em contato com você com newsletters, materiais de marketing ou promocionais e outras informações que possam ser do seu interesse.</p>
      <p><strong>Compartilhamento de Informações:</strong> Não compartilhamos suas informações pessoais com terceiros, exceto quando necessário para operar nosso aplicativo.</p>
      <p><strong>Segurança:</strong> Valorizamos sua confiança ao nos fornecer suas informações pessoais, e estamos empenhados em protegê-las. No entanto, lembre-se de que nenhum método de transmissão pela Internet ou método de armazenamento eletrônico é 100% seguro.</p>
      <p><strong>Links para Outros Sites:</strong> Nosso aplicativo pode conter links para outros sites. Se você clicar em um link de terceiros, será direcionado para esse site. Recomendamos que você revise a Política de Privacidade de cada site que visita.</p>
      <p><strong>Assinatura Mensal:</strong> Ao assinar o plano mensal do Task App, você terá acesso total ao aplicativo, sem limites e sem anúncios. O valor da assinatura será cobrado mensalmente pelo uso do aplicativo.</p>
      <p><strong>Alterações na Política de Privacidade:</strong> Esta Política de Privacidade pode ser atualizada periodicamente. Aconselhamos que você revise esta página regularmente para quaisquer alterações.</p>
      <p>Ao utilizar nosso aplicativo, você concorda com o processamento de suas informações conforme descrito nesta Política de Privacidade.</p>
    </Contaienr>
  )
}
export default PolicyPrivacyPage;