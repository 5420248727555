import * as React from "react";
import {
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import TermsOfUserPage from "../screens/termsOfUserPage";
import PolicyPrivacyPage from "../screens/policyPrivacyPage";


const router = createBrowserRouter([
  {
    path: "/terms-of-use",
    element: <TermsOfUserPage />,
  },
  {
    path: "/policy-privacy",
    element: <PolicyPrivacyPage />
  }
]);


const RouteApp = () => {
  return <RouterProvider router={router} />
}

export default RouteApp;