import styled from 'styled-components'

export const Contaienr = styled.div`
  flex: 1;
  flex-direction: column;
  margin: 0px 20px 0px 20px;

  p {
    font-size: 15px;
    font-family: "Pontano Sans", sans-serif;
  }
  h1 { 
    text-align: center;
    text-decoration: underline;
    font-family: "Pontano Sans", sans-serif;
    margin-bottom: 50px;
    font-size: 18px;
  }
`