
import React from "react";
import { Contaienr } from "./styles";

const TermsOfUserPage = () => {
  return (
    <Contaienr>
      <h1>Termos de uso do Task App</h1>
      <p>Bem-vindo ao Task App! Ao utilizar nosso aplicativo, você concorda com os seguintes termos e condições:</p>
      <p><strong>Aceitação dos Termos:</strong> Ao utilizar o Task App, você concorda em cumprir estes Termos de Uso e todas as leis e regulamentos aplicáveis. Se você não concordar com algum destes termos, por favor, não use o aplicativo.</p>
      <p><strong>Cadastro:</strong> Ao se cadastrar no Task App, você concorda em fornecer informações verdadeiras, precisas, atuais e completas sobre si mesmo conforme solicitado pelo formulário de registro.</p>
      <p><strong>Segurança da Conta:</strong> Você é responsável por manter a segurança de sua conta e senha. Não compartilhe suas credenciais de login com terceiros.</p>
      <p><strong>Privacidade:</strong> Respeitamos sua privacidade. Todas as informações pessoais fornecidas ao Task App serão tratadas de acordo com nossa Política de Privacidade.</p>
      <p><strong>Publicidade:</strong> O Task App pode conter anúncios. A exibição de anúncios está sujeita aos termos de serviço do provedor de anúncios e à Política de Privacidade do Task App.</p>
      <p><strong>Uso Adequado:</strong> Você concorda em usar o Task App apenas para fins legítimos. Você não deve usar o aplicativo de forma que viole qualquer lei ou regulamento, ou que infrinja os direitos de terceiros.</p>
      <p><strong>Propriedade Intelectual:</strong> O Task App e todo o seu conteúdo, incluindo, mas não se limitando a, texto, gráficos, logotipos, ícones e imagens, são propriedade do Task App ou de seus licenciadores e estão protegidos por leis de direitos autorais e outras leis de propriedade intelectual.</p>
      <p><strong>Alterações nos Termos:</strong> O Task App reserva-se o direito de modificar estes Termos de Uso a qualquer momento. Alterações significativas serão notificadas aos usuários por meio do aplicativo ou por outros meios adequados. O uso contínuo do Task App após tais alterações constituirá sua aceitação dos novos termos.</p>
      <p><strong>Rescisão:</strong> O Task App reserva-se o direito de suspender ou rescindir sua conta e acesso ao aplicativo a qualquer momento, por qualquer motivo, sem aviso prévio.</p>
      <p><strong>Lei Aplicável:</strong> Estes Termos de Uso serão regidos e interpretados de acordo com as leis do Brasil.</p>
    </Contaienr>
  )
}
export default TermsOfUserPage;